import React, { useMemo } from 'react';
import Typewriter from 'typewriter-effect';
import { useUser } from '@/providers/auth0';
import Section from '@/components/integrated/section';
import PromotionalBanner from '@/components/integrated/promotional-banner';
import VersatileCard from '@/components/integrated/versatile-card';
import HomePersonalisedSection from '@/modules/home/home-personalised-section';
import HomeTopPicksCarousel from '@/modules/home/home-top-picks-carousel';
import { useFeatureTogglesContext } from '@/context/feature-toggles/feature-toggles-context';
import { FEATURE_TOGGLE_LIST } from '@/context/feature-toggles/feature-toggles.types';
import SearchSection from '@/components/integrated/search-section';
import { HomePageModuleProperties } from './home-page-module.types';
import styles from './home-page-module.module.scss';

const HomePageModule = ({
  homePersonalisedSection,
  homeTopPicksCarouselProperties,
  promotionalBanner,
  homeLastVisitedProperties,
  homeLastPurchasesProperties,
  amexBanner,
  nextBestAquisition,
  nextBestRetention,
  microcopy,
  pageHeading,
  pageHeadingTypewritter,
  heroSection,
}: HomePageModuleProperties) => {
  const featuresToggles = useFeatureTogglesContext();
  const { user, isLoading } = useUser();

  const showTopPicks = useMemo(
    () =>
      featuresToggles.includes(FEATURE_TOGGLE_LIST.TOP_PICKS) &&
      !!user &&
      !!homeTopPicksCarouselProperties,
    [homeTopPicksCarouselProperties, user, featuresToggles],
  );

  const renderPersonalisedSection =
    user && featuresToggles.includes(FEATURE_TOGGLE_LIST.PERSONALISED_SECTION);

  const amexVersatileCardProperties =
    user && nextBestAquisition
      ? {
          Prem_Plus: amexBanner.premium, // Once we know what the FS returns for the NBA we can have these responses in a enum
          Classic: amexBanner.classic,
          SBS: amexBanner.business,
        }[nextBestAquisition]
      : amexBanner.generic;

  const renderPageHeading = () =>
    !user && (pageHeading || pageHeadingTypewritter);

  if (isLoading) return null;

  return (
    <>
      {heroSection && (
        <SearchSection
          searchButtonLabel={heroSection.searchButtonLabel}
          noResultsLabel={heroSection.noResultsLabel}
          image={heroSection.image}
          pillLabel={heroSection.pillLabel}
          heading={heroSection.heading}
          body={heroSection.body}
          button={heroSection.button}
          backgroundColour={heroSection.backgroundColour}
          enableSearch={heroSection.enableSearch}
          wasRateLabel={heroSection.wasRateLabel}
          fromRateLabel={heroSection.fromRateLabel}
          upToRateLabel={heroSection.upToRateLabel}
          closeButtonLabel={heroSection.closeButtonLabel}
        />
      )}
      {promotionalBanner?.showPromotion && (
        <Section
          ariaLabel={microcopy?.home_section_arialabel_infobanner}
          className={styles[`home-page-module__section`]}
        >
          {promotionalBanner?.showPromotion && (
            <PromotionalBanner
              title={promotionalBanner.promotionTitle}
              description={promotionalBanner.promotionText}
              terms={promotionalBanner.promotionTerms}
              ctaUrl={promotionalBanner.promotionPath}
              backgroundColor={promotionalBanner.promotionBackgroundColor}
            />
          )}
        </Section>
      )}
      {renderPageHeading() && (
        <Section ariaLabel={microcopy?.home_section_arialabel_heading}>
          <h1 className={styles[`home-page-module__heading`]}>
            {pageHeading}
            {pageHeadingTypewritter && (
              <Typewriter
                options={{
                  strings: pageHeadingTypewritter,
                  autoStart: true,
                  loop: true,
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  pauseFor: 1000,
                }}
              />
            )}
          </h1>
        </Section>
      )}
      {renderPersonalisedSection && (
        <Section
          ariaLabel={microcopy?.home_section_arialabel_tailoredforyou}
          className={styles[`home-page-module__section`]}
        >
          {renderPersonalisedSection && (
            <HomePersonalisedSection
              {...homePersonalisedSection}
              {...homeLastVisitedProperties}
              {...homeLastPurchasesProperties}
              microcopy={microcopy}
            />
          )}
        </Section>
      )}

      {showTopPicks && (
        <HomeTopPicksCarousel
          {...homeTopPicksCarouselProperties}
          sectionDescription={microcopy?.home_section_arialabel_toppicks}
        />
      )}

      {amexVersatileCardProperties && !nextBestRetention && (
        <Section ariaLabel={microcopy?.home_section_arialabel_creditcardoffer}>
          <VersatileCard {...amexVersatileCardProperties} />
        </Section>
      )}
    </>
  );
};

export default HomePageModule;

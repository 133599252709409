export const xs = 479;
export const sm = 767;
export const md = 1023;
export const lg = 1439;
export const xl = 1919;

export const BREAKPOINTS = {
  xs: `(max-width: ${xs}px)`,
  sm: `(max-width: ${sm}px)`,
  md: `(max-width: ${md}px)`,
  lg: `(max-width: ${lg}px)`,
  xl: `(max-width: ${xl}px)`,
};

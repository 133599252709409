import { CustomLink as Link } from '@/components/custom-link';
import Icon from '@/components/data-display/icon';
import { VARIANT as ICON_VARIANT } from '@/components/data-display/icon/icon.types';
import { PromotionalBannerProperties } from './promotional-banner.types';
import styles from './promotional-banner.module.scss';

const PromotionalBanner = ({
  className = '',
  title,
  titleColour,
  description,
  descriptionColour,
  terms,
  termsColour,
  backgroundColor,
  ctaUrl,
}: PromotionalBannerProperties) => {
  const titleStyles = {
    color: titleColour ?? undefined,
  };
  const descriptionStyles = {
    color: descriptionColour ?? undefined,
  };
  const termsStyles = {
    color: termsColour ?? undefined,
  };

  const containerStyles = {
    backgroundColor: backgroundColor ?? undefined,
  };

  return (
    <div
      title={title}
      className={`${styles['promotional-banner']} ${className}`}
      style={containerStyles}
    >
      {title && (
        <h3 className={styles['promotional-banner__title']} style={titleStyles}>
          {title}
        </h3>
      )}
      {description && (
        <p
          className={styles['promotional-banner__body']}
          style={descriptionStyles}
        >
          {description}
        </p>
      )}
      {terms && (
        <Link
          className={styles['promotional-banner__terms']}
          style={termsStyles}
          href={ctaUrl ?? '/'}
          target="_blank"
          legacyBehavior={false}
        >
          {terms}
          <Icon variant={ICON_VARIANT.ExternalLink} />
        </Link>
      )}
    </div>
  );
};

export default PromotionalBanner;

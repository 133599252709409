import { GetServerSidePropsContext } from 'next';
import { UserProfile, getSession } from '../../../providers/auth0';
import { HomePageModuleProperties } from './home-page-module.types';
import ContentfulProvider from '../../../providers/contentful';
import { homePageModuleQuery } from './home-page-module.query';
import FSProvider from '../../../providers/financial-services/financial-services.provider';
import { formatMicrocopy } from '../../../utils/format-microcopy';
import { processPersonalisedSection } from './home-page-module.utils';
import {
  formatSearchSection,
  formatVersatileCard,
  handleImageDescription,
} from '../../../utils/contentful-data-formatters/contentful-data-formatters';
import { getMembershipId } from '../../../utils/extract-user-data';
import { featureTogglesHandler } from '../../../utils/shared-handler/feature-toggle-handler';
import { FEATURE_TOGGLE_LIST } from '../../../context/feature-toggles/feature-toggles.types';

export const homePageModuleHandler = async ({
  req,
  res,
}: // eslint-disable-next-line sonarjs/cognitive-complexity
GetServerSidePropsContext): Promise<HomePageModuleProperties> => {
  const { market, b2bToken } = req;
  const featureToggleList = await featureTogglesHandler(market);

  const contentfulRequest = new ContentfulProvider(
    market,
  ).makeContentfulRequest<any>({
    query: homePageModuleQuery,
  });

  const session = await getSession({ market, requestResponse: [req, res] });

  const fsResponse =
    session?.user && featureToggleList.includes(FEATURE_TOGGLE_LIST.AMEX_MGM)
      ? await new FSProvider(market).getFinancialServices(
          getMembershipId(session.user),
        )
      : null;

  const contentfulResponse = await contentfulRequest;

  const {
    data: {
      pageHomeLinkedMediaContentCollection: { items },
      microcopyCollection: { items: textItems },
    },
  } = contentfulResponse;

  const microcopy = formatMicrocopy(textItems);

  const userId = getMembershipId(session?.user as UserProfile);
  const personalisedSection = await processPersonalisedSection(
    userId as string,
    items[0]?.personalisedSection,
    market,
    b2bToken,
    microcopy.merchant_rateupto,
    microcopy.merchant_ratewas,
    microcopy.home_personalisedsection_ctalabel,
  );

  const heroSection = items?.[0].heroSection;

  return {
    microcopy,
    homePersonalisedSection: {
      titleLabel: personalisedSection.titleLabel,
      paidForMerchant: personalisedSection.paidForMerchant,
      paidForMerchantHeroImage: personalisedSection.paidForMerchantHeroImage,
      noFavouritesImage: {
        url: items?.[0].noFavouritesImage?.url ?? null,
        title: items?.[0].noFavouritesImage?.title ?? null,
        description: handleImageDescription(
          items?.[0].noFavouritesImage?.title,
          items?.[0].noFavouritesImage?.description,
        ),
      },
      noLastVisitedImage: {
        url: items?.[0].noLastVisitedImage?.url ?? null,
        title: items?.[0].noLastVisitedImage?.title ?? null,
        description:
          items?.[0].noLastVisitedImage?.url &&
          handleImageDescription(
            items?.[0].noLastVisitedImage?.title,
            items?.[0].noLastVisitedImage?.description,
          ),
      },
      noPendingPurchases: {
        title: microcopy?.home_nopendingpurchases_title,
        description: microcopy?.home_nopendingpurchases_description,
      },
      noAwardedPurchases: {
        title: microcopy?.home_noawardedpurchases_title,
        description: microcopy?.home_noawardedpurchases_description,
      },
      favouritesLabels: {
        addToFavourites: microcopy?.favourites_button_addtofavourites ?? null,
        removeFromFavourites:
          microcopy?.favourites_button_removefromfavourites ?? null,
      },
    },
    promotionalBanner: {
      showPromotion: items?.[0].showPromotion,
      promotionTerms: items?.[0].promotionTerms,
      promotionTitle: items?.[0].promotionTitle,
      promotionText: items?.[0].promotionText,
      promotionPath: items?.[0].promotionPath,
      promotionBackgroundColor: items?.[0].promotionBackgroundColor,
    },
    amexBanner: {
      generic:
        items?.[0].amexBannerV2 && formatVersatileCard(items?.[0].amexBannerV2),
      premium:
        items?.[0].amexPremiumBannerV2 &&
        formatVersatileCard(items?.[0].amexPremiumBannerV2),
      classic:
        items?.[0].amexClassicBannerV2 &&
        formatVersatileCard(items?.[0].amexClassicBannerV2),
      business:
        items?.[0].amexBusinessBannerV2 &&
        formatVersatileCard(items?.[0].amexBusinessBannerV2),
    },
    nextBestAquisition: fsResponse?.Next_best_acquisition_FS1 ?? null,
    nextBestRetention: fsResponse?.Next_best_retention_FS1 ?? null,
    pageTitle: items?.[0].pageTitle || market.pageTitle,
    pageDescription: items?.[0].pageDescription || null,
    pageKeywords: items?.[0].pageKeywords?.join(', ') || null,
    pageHeading: items?.[0].pageHeading || null,
    pageHeadingTypewritter: items?.[0].pageHeadingTypewritter || null,
    heroSection: heroSection
      ? formatSearchSection(heroSection, microcopy)
      : null,
  };
};

import React, { FC, useEffect, useRef, useState } from 'react';
import { sm } from '@/utils/breakpoints/breakpoints';
import { appendImageFormatParameters } from '@/utils/contentful-image-paths/contentful-image-paths';
import Image from '@/components/data-display/image';
import Button from '@/components/inputs/button';
import { VARIANT } from '@/components/inputs/button/button.types';
import Icon from '@/components/data-display/icon';
import { VARIANT as ICON_VARIANT } from '@/components/data-display/icon/icon.types';
import SearchModal from '@/modules/search-modal';
import { toggleModal } from '@/components/utilities/modal';
import { Eyebrow, Heading } from '@alto-avios/alto-ui';
import { SearchSectionProperties } from './search-section.types';
import styles from './search-section.module.scss';

const heroImageDimensions = {
  mobileWidth: 1000,
  mobileHeight: 500,
  desktopWidth: 1920,
  desktopHeight: 567,
};

const SearchSection: FC<SearchSectionProperties> = ({
  searchButtonLabel,
  image,
  pillLabel,
  heading,
  body,
  button,
  wasRateLabel,
  upToRateLabel,
  fromRateLabel,
  closeButtonLabel,
  noResultsLabel,
  backgroundColour,
  enableSearch,
}) => {
  const modalLabel = 'search-modal';
  const containerReference = useRef<HTMLDivElement>(null);
  const [imageMaxWidth, setImageMaxWidth] = useState(600);
  const [imageMaxHeight, setImageMaxHeight] = useState(327);

  const selectResponsiveImage = () => {
    if (containerReference.current) {
      setImageMaxWidth(
        containerReference.current.clientWidth > sm
          ? heroImageDimensions.desktopWidth
          : heroImageDimensions.mobileWidth,
      );

      setImageMaxHeight(
        containerReference.current.clientWidth > sm
          ? heroImageDimensions.desktopHeight
          : heroImageDimensions.mobileHeight,
      );
    }
  };

  useEffect(() => {
    selectResponsiveImage();

    window.addEventListener('resize', selectResponsiveImage);
    return () => window.removeEventListener('resize', selectResponsiveImage);
  }, []);

  const imageURL = image
    ? appendImageFormatParameters({
        url: image.url,
        maxWidth: imageMaxWidth,
        maxHeight: imageMaxHeight,
        quality: 75,
      })
    : '';

  return (
    <section
      ref={containerReference}
      className={styles[`search-section`]}
      style={{
        background: backgroundColour ?? 'initial',
      }}
    >
      {image && (
        <Image
          className={styles[`search-section__image`]}
          src={imageURL}
          alt={image.description}
          priority
          quality={100}
          width={imageMaxWidth}
          height={imageMaxHeight}
        />
      )}
      <div className={styles[`search-section__card`]}>
        {pillLabel && (
          <div className={styles[`search-section__eyebrow`]}>
            <Eyebrow as="span" styleVariant="collect">
              {pillLabel}
            </Eyebrow>
          </div>
        )}

        {(heading || body) && (
          <div className={styles[`search-section__card-text`]}>
            {heading && (
              <Heading
                as="h1"
                size="lg"
                textAlign="center"
                foregroundColor="accentSecondary"
              >
                {heading}
              </Heading>
            )}
            {body && <p>{body}</p>}
          </div>
        )}

        {button.label && button.url && (
          <Button
            as="link"
            variant={VARIANT.Text}
            href={button.url}
            className={styles[`search-section__card-button`]}
            trailingIcon={ICON_VARIANT.ArrowRight}
            target={button.openLinksInNewTab ? '_blank' : '_self'}
          >
            {button.label}
          </Button>
        )}

        {enableSearch && (
          <Button
            className={styles[`search-section__card-search-field-button`]}
            variant={VARIANT.Unstyled}
            onClick={() => toggleModal(modalLabel)}
          >
            <div className={styles[`search-section__card-search-field`]}>
              <Icon
                variant={ICON_VARIANT.Search}
                className={styles[`search-section__card-search-field-icon`]}
              />
              <span>{searchButtonLabel}</span>
            </div>
          </Button>
        )}
      </div>

      {enableSearch && (
        <SearchModal
          modalLabel={modalLabel}
          wasLabel={wasRateLabel}
          upToLabel={upToRateLabel}
          fromLabel={fromRateLabel}
          title={searchButtonLabel}
          closeButtonLabel={closeButtonLabel ?? ''}
          noResultsLabel={noResultsLabel ?? ''}
        />
      )}
    </section>
  );
};

export default SearchSection;
